/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.logo {
    background-color: #FFDD00;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoInner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 900px;
    margin: 20px;
    padding: 30px;
    /* font-family: "Bebas Neue", sans-serif; */
    font-family: "Chakra Petch", sans-serif;
    /* background-color: rgb(196, 196, 229); */
}

.logo h2 {
    font-size: 60px;
    color: red;
    letter-spacing: 2px;
    user-select: none;
    text-transform: uppercase;
}

.logo img {
    width: 50px;
    margin-right: 10px;
}

.logo h2 span {
    color: black;
    display: inline-block;
}


.socialMediaIcon {
    position: absolute;
    top: 145px;
    right: 1.6vw;
    z-index: 101;
}

.socialMediaIcon i {
    font-size: 20px;
    margin: 0px 4px;
    cursor: pointer;
    padding: 8px;
    transition: 0.4s;
    border-radius: 50%;
    background-color: #011E29;
    color: white;
}

.socialMediaIcon i:hover {
    color: red;
    background-color: #011E29;
}



/* -------------------------- Media Queries */

@media (max-width:980px) {
    .logoInner {
        width: 700px;
        padding: 20px 15px;
    }

    .logo h2 {
        font-size: 40px;
    }

    .socialMediaIcon {
        top: 120px;
        right: 0.9vw;
    }
}

@media (max-width:690px) {
    .logoInner {
        width: 790px;
        padding: 20px 10px;
    }

    .logo h2 {
        font-size: 35px;
    }

    .socialMediaIcon {
        top: 117px;
        right: 0.6vw;
    }
}

@media (max-width: 650px) {
    .socialMediaIcon {
        display: none;
    }
}

@media (max-width:570px) {
    .logoInner {
        width: 100vw;
        padding: 20px 0px;
        margin: 7px;
    }

    .logo h2 {
        font-size: 35px;
    }

    .logo img {
        width: 37px;
    }
}

@media (max-width: 462px) {
    .logo h2 {
        font-size: 33px;
    }
}

@media (max-width: 441px) {
    .logo h2 {
        font-size: 31px;
    }
}

@media (max-width: 420px) {
    .logo h2 {
        font-size: 29px;
    }
}


@media (max-width:400px) {
    .logoInner {
        width: 100vw;
        padding: 20px 0px;
        margin: 7px;
    }

    .logo h2 {
        font-size: 25px;
    }

    .logo img {
        width: 30px;
        margin-right: 5px;
    }
}


@media (max-width: 345px) {

    .logo h2 {
        font-size: 22px;
    }
}