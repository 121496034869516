.alertBox {
    background-color: transparent;
    position: fixed;
    top: 85vh;
    width: 100%;
    z-index: 101;
}


.alertBoxInner {
    float: right;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.7vw 1.2vw;
    margin-right: 1%;
    /* border-radius: 10px; */
    background-color: white;
    color: black;
    transform: translateX(100%);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    animation: moveleft 0.5s linear forwards;
    overflow: hidden;
    opacity: 0;
    position: relative;
}

@keyframes moveleft {
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.alertBoxInner h1 {
    font-size: 20px;
}

.alertBoxInner p {
    font-size: 20px;
    background-color: green;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    padding-top: 4px;
    margin-right: 10px;

}

.alertBoxInner p.error {
    background-color: red;
}

.alertBoxInner::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    animation: anim 2s linear forwards;
}

.successBox::after {
    background-color: green;
}

.errorBox::after {
    background-color: red;
}


@keyframes anim {
    100% {
        width: 0;
    }
}


/* --------------------- Media Queries */

@media (max-width:1240px) {
    .alertBoxInner {
        width: 30%;
    }

    .alertBoxInner h1 {
        font-size: 18px;
    }
}

@media (max-width:950px) {
    .alertBoxInner {
        width: 40%;
    }

    .alertBoxInner h1 {
        font-size: 17px;
    }
}

@media (max-width:650px) {
    .alertBoxInner {
        width: 55%;
    }

    .alertBoxInner h1 {
        font-size: 16px;
    }
}

@media (max-width:460px) {
    .alertBoxInner {
        width: 76%;
    }

    .alertBoxInner h1 {
        font-size: 15px;
    }
}

@media (max-width:345px) {
    .alertBoxInner {
        width: 80%;
    }

    .alertBoxInner h1 {
        font-size: 14px;
    }
}