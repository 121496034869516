.login {
    background-color: white;
    min-height: 67vh;
    width: 100%;
    padding: 5vw 10vw;
}

.loginBox {
    display: block;
    width: 28vw;
    margin: 0px auto;
    /* background-color: rgb(116, 116, 190); */
    border: 5px solid #011E29;
    padding: 30px 30px;
    border-radius: 30px;
}

.loginBox h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    color: #011E29;
    font-weight: 900;
}

.loginBox form #inputEmailField {
    border: none;
    outline: none;
    border-bottom: 2px solid #011E29;
    border-radius: 0px;
    padding: 10px 7px;
    background-color: transparent;
    color: black;
    width: 100%;
}

.inputPasswordField {
    border-bottom: 2px solid #011E29;
    position: relative;
}

.inputPasswordField input {
    border: none;
    outline: none;
    border-radius: 0px;
    padding: 10px 7px;
    background-color: transparent;
    color: black;
    width: 90%;
    margin: 0px 0px 0px;
}

.inputPasswordField #showBtn {
    position: absolute;
    top: 6px;
    right: 0px;
    padding: 4px 10px;
    background-color: gainsboro;
    color: black;
    font-size: 15px;
    cursor: pointer;
    border-radius: 3px;
}


.loginBox form input::placeholder {
    color: black;
}

.loginBox form button {
    display: block;
    padding: 15px 9vw;
    border-radius: 30px;
    margin: 0px auto;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #011E29;
    color: white;
    border: 3px solid #011E29;
    transition: 0.4s;
}

.loginBox form button:hover {
    background-color: white;
    color: #011E29;
}

.loginBox p {
    text-align: center;
    font-size: 16px;
    color: black;
}

.loginBox p span {
    color: #011E29;
    font-weight: 700;
}

.loginBox p span:hover {
    text-decoration: underline;
}




/* ------------------------- Media Queries */

@media (max-width:1100px) {
    .loginBox {
        width: 40vw;
    }

    .loginBox h2 {
        font-size: 24px;
    }

    .loginBox form label {
        font-size: 20px;
    }
}

@media (max-width:970px) {
    .loginBox {
        width: 50vw;
    }

    .loginBox h2 {
        font-size: 24px;
    }

    .loginBox form label {
        font-size: 20px;
    }
}

@media (max-width:620px) {
    .loginBox {
        width: 70vw;
    }

    .loginBox h2 {
        font-size: 24px;
    }

    .loginBox form label {
        font-size: 18px;
    }
}

@media (max-width:420px) {
    .login {
        padding: 5vw 2vw;
        margin-top: 40px;
    }

    .loginBox {
        width: 90vw;
    }

    .loginBox h2 {
        font-size: 22px;
    }

    .loginBox form label {
        font-size: 16px;
    }

    .inputSPasswordField input {
        font-size: 13px;
    }
}