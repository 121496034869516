.addMagazine {
    /* background-color: lightblue; */
    width: 100%;
    height: 100%;
    margin-top: 40px;
    padding: 0 25%;
}

.addMagazine.userMenu {
    /* background-color: blue; */
    margin-top: -190px;
}


.addMagazine.showMenu {
    margin-top: -50px;
}


.addMagazineInner {
    /* background-color: rgb(227, 173, 173); */
    height: 100%;
    width: 100%;
    display: block;
    margin: 0px auto 60px;
    border-radius: 30px;
    border: 5px solid #011E29;
}

.addMagazineInner h1 {
    text-align: center;
    padding-top: 20px;
}

.addMagazineForm {
    margin: 20px 0px 40px;
    /* background-color: rgb(226, 189, 233); */
    padding: 30px 30px 20px;
}

label {
    font-size: 20px;
    font-weight: 600;
}

input,
textarea {
    display: block;
    width: 100%;
    margin: 5px 0px;
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    resize: none;
    font-size: 15px;
    /* background-color: #022c3d;
    color: white; */
    border: 3px solid #011E29;
}

select {
    padding: 5px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #022c3d;
    color: white;
}

.submitBtn {
    width: 140px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #022c3d;
    color: white;
    float: right;
    border-radius: 10px;
    display: block;
}



.uploadBtn {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #022c3d;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: 3px;
    margin-top: 10px;
}

#titleCss {
    display: block;
    margin-top: 20px;
}



/* ------------------------Responsive Design-------*/

@media (max-width: 950px) {
    .addMagazine {
        padding: 0 15%;
    }

}

@media (max-width: 750px) {
    .addMagazine {
        padding: 0 5%;
    }
}

@media (max-width: 400px) {


    .addMagazineInner h1 {
        font-size: 28px;
    }

    .addMagazineForm {
        padding: 30px 30px 70px;
    }


    label {
        font-size: 18px;
        font-weight: 600;
    }

}