.comments {
    padding: 0 17%;
    /* background-color: blue; */
    margin-top: 50px;
}

.commentInner {
    /* background-color: rebeccapurple; */
    width: 100%;
    padding-bottom: 30px;
}

.commentInner h2 {
    font-size: 25px;
    margin-bottom: 20px;
}

.commentInner .hideLogin {
    display: none;
}

.commentInner input {
    border: none;
    outline: none;
    border-bottom: 3px solid #011E29;
    border-radius: 0px;
    padding: 5px;
    margin-bottom: 100px;
}

.commentInner input::selection {
    background-color: black;
    color: white;
}

.commentBtn {
    float: right;
    margin-top: -75px;
    padding: 12px 25px;
    border: none;
    outline: none;
    background-color: #011E29;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 17px;
    transition: 0.5s;
}

.commentBtn:hover {
    background-color: #D7E4E8;
    color: #011E29;
}

.commentItem {
    width: 100%;
    border-radius: 10px;
    transition: 0.5s;
    /* background-color: blue; */
}

.commentInnerx {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    padding: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-bottom: 3px solid #e1e4e5;
    border-radius: 10px;
}

.commentInnerx img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 3px solid #011E29;
}

.commentInnerBox {
    display: flex;
    flex-direction: column;
}

.commentInnerNameAndTime {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 5px;
}

.commentInnerNameAndTime h3 {
    margin-right: 15px;
}

.commentContent {
    font-size: 16px;
}

.commentInnerNameAndTime p {
    font-size: 14px;
}

.commentSignInBox {
    margin-top: 60px;
}

.commentSignInBox button {
    margin-top: 10px;
    padding: 13px 35px;
    border: none;
    outline: none;
    border-radius: 20px;
    color: white;
    background-color: #011E29;
    transition: 0.5s;
    cursor: pointer;
}

.commentSignInBox button:hover {
    background-color: rgb(237, 236, 236);
    color: #011E29;
}



/* ---------------------- Media Queries */

@media (max-width:540px) {
    .comments {
        padding: 0 5%;
    }


    .commentContent {
        font-size: 14px;
        /* background-color: blue; */
    }
}

@media (max-width:400px) {

    .commentInner h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .commentInnerx img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .commentInnerNameAndTime {
        flex-direction: column;
    }

    .commentContent {
        font-size: 14px;
        /* background-color: rgb(204, 204, 230); */
        width: 80vw;
        margin-left: -16vw;
    }

    .commentSignInBox p {
        font-size: 15px;
    }

    .commentInnerNameAndTime p {
        /* margin-left: -20vw;*/
        margin-bottom: 15px;
        font-size: 13px;
        /* background-color: blue; */
    }
}